footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 180px;
    background-color: #F1F1F1;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

footer ul {
    display: flex;
    margin: 0;
    padding: 0em;
}
footer ul li {
    padding: 16px;
}

footer ul li a {
    font-size: 28px;
}
footer a:hover,
footer a:focus {
   color: #5461C8;
}

footer a#email {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 2px solid #1d1d1d;
}
footer a#email:hover,
footer a#email:focus {

    border-bottom: 2px solid #5461C8;
}

nav {
    min-height: 72px;
    padding: 0;
    display: flex;
    justify-content: center;
  }
  nav .desktop {
      padding: 16px 0;
      margin: 0 16px;
      max-width: 1024px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
  }

  nav .desktop ul {
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
  }
  
  nav .desktop ul li {
      padding: 0px 16px;
  }
  nav .desktop ul li:last-of-type {
      padding: 0 0 0 16px;
  }
  
  nav .desktop ul li a {
      font-size: 28px;
      font-weight: 500;
      line-height: 32px;
  }
  nav .desktop ul li a#email {
      font-size: 32px;

  }

  nav .desktop ul li a:hover,
  nav .desktop ul li a:focus {
      color: #5461C8;
  }

    .hamburger {
        display: none;
    }


@media (max-width: 768px) {
  nav {
      width: 100%;
      min-height: 72px;
      margin: 0;
      padding: 8px 24px 0 16px;
      display: flex;
      justify-content: center;
  }

  nav .desktop  {
      display: none;
  }

.hamburger {
    display: flex;
    z-index:3;
    position: relative;
    -webkit-user-select: none;
    user-select: none;
    width: 40px;
    height: 32px;
    margin: 10px auto 0 16px;
} 

.hamburger-lines {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    /* right: 20px; */
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.hamburger input {
    width: 40px;
    height: 32px;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 5;
    opacity: 0;
    -webkit-touch-callout: none;
    cursor: pointer;
}

.hamburger-lines div {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 6px;
    background: #1d1d1d;
    transition: transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.35s ease;
}

.hamburger div:first-of-type {
    transform-origin: 0% 0%;
    
  }

  .hamburger div:nth-of-type(2) {
    transition: transform 0.2s ease-in-out
  }

.hamburger div:last-of-type {
    transform-origin: 0% 100%;
    
  }

  .hamburger input[type="checkbox"]:checked ~ .hamburger-lines div:first-of-type {
    transform: rotate(45deg);
  }

  .hamburger input[type="checkbox"]:checked ~ .hamburger-lines div:nth-of-type(2) {
    transform: scaleY(0);
  }
  
  .hamburger input[type="checkbox"]:checked ~ .hamburger-lines div:last-of-type {
    transform: rotate(-45deg);
  }


.hamburger ul {
    z-index: 3;
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: hidden;
    left: -260px;
    top: -20px;
    background-color: rgba(255, 255, 255, 0.9);
    transform: translate(-150%, 0);
    /* Height set by js to 100% of document height */
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 96px 0 0 24px;
    opacity: 0;
    border-bottom: 6px solid #5461C8;
    transform-origin: 0% 0%;
    transition: transform 0.4s cubic-bezier(0.77, 0.2, 0.05, 1),
    opacity 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
      /* Stops flickering of text in Safari */
      -webkit-font-smoothing: antialiased;
  }
  
  /* Backdrop-filter not supported in firefox */
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      .hamburger ul {
        background-color: rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(16px);
      }
    }


  .hamburger input[type="checkbox"]:checked ~ ul {
    transform: translateX(225px);
    opacity: 1;

  }

  .hamburger ul li {
    padding: 8px 10px;
  }
  .hamburger ul li a {
    font-size: 40px;
    font-weight: 500;
  }
  .hamburger ul li a:hover,
  .hamburger ul li a:active {
    color: #5461C8;
    border-bottom: 2px solid #5461C8;
  }


  }
  