

main {
  width: 100%;
  margin: 0 auto;
  padding: 0;
  max-width: 1024px;
 
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
}
h1 {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
  padding: 0 48px 16px 48px;
}
h2 {
  font-size: 24px;
  font-weight: 500;
}

p {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0;
  text-align: center;
}

.tagline {
  font-weight: 500;
  color: #171923a4;
  padding: 0 0 16px 0;
}

@media (max-width: 768px) {
  main{
    width: 95vw;
    padding: 0;
     overflow-x: hidden;
  }

  h1 {
    font-size: 40px;
  }
}

