
.cards-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    max-width: 800px;
    padding: 40px 0 80px 0;
    margin: 0 auto;
  }

.card {
    position: relative;
    /* height: 400px; */
    width: 100%;
    display: flex;
    padding: 32px 48px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    margin: 0 auto 80px auto;
    
}

.card#marcereads {
    background-color: #D8E9E4;
}
.card#nagasaki {
    background-color: #3F5B71;
    color: #fff;
}
.card#brcodify {
    background-color: rgba(23, 25, 35, 0.9);
    color: #fff;
    overflow: hidden;
}
.card#weathr {
    background-color: #F48B6D;
    color: #fff;
    overflow: hidden;
}
.card#nagasaki h3 {
    color: initial;
}
.card#brcodify h3 {
    color: initial;
}
.card#weathr h3 {
    color: initial;
}

.card-text-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: flex-start; */
    width: 375px;
}
.card-text-content p {
    padding: 0 0 18px 0;
}

.card-text-content h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 24px 0 16px 0;
}

.card img {
   position: absolute;
   right: -65px;
   top: -75px;
   height: 555px; 
   width: 555px;
}

.card#brcodify img {
   position: absolute;
   right: -260px;
   top: -75px;
   height: 530px; 
   width: 775px;
}

.card img.overflow {
   position: absolute;
   right: -135px;
   top: -95px;
   height: 625px; 
   width: 625px;
}


.project-tags-container {
     display: flex;
}

.project-type-tag {
    background-color: #fff;
    border-radius: 50px;
    padding: 4px 24px;
    margin: 0 12px 0 0;
    font-weight: 500;
    font-size: 16px;
}

.project-type-tag:last-of-type {
    margin: 0;
}

#marcereads a {
    background-color: #FEE9FF;
}
#nagasaki a {
    background-color: #FF7262;
}
#brcodify a {
    background-color: #D1398D;
}
#weathr a {
    background-color: #0A4B64;
}

.tags-container {
    display: flex;
    margin: 40px 0 0 0;
}

.tag {
    font-size: 16px;
    font-weight: 500;
    margin-right: 16px;
}

.tag svg {
    padding: 0 8px 0  0;
    font-size: 24px;
    vertical-align: middle;
}

.card:hover .button,
.card:focus .button {
    opacity: 1;
} 


@media (max-width: 768px) {
    .cards-container {
        width: 100%;
      }
    .card {
        height: 775px;
        display: flex;
        flex-direction: column;
        padding: 16px 24px 24px 24px;
        margin: 0 auto 100px auto;
    }
    .card-text-content {
        width: 100%;
    }

    .card img {
        position: absolute;
        right: -25px;
        top: 350px;
        height: 455px; 
        width: 455px;
        overflow-x: hidden;
     }
     
     .card#brcodify{
         height: 725px;
     }
     .card#brcodify img {
        position: absolute;
        /* transform: translate(-12%, 0%); */
        right: 0;
        left: -55px;
        top: 360px;
        width: 500px;
        height: unset;
     }
     
     .card img.overflow {
        position: absolute;
        /* right: -75px; */
        left: -45px;
        top: 340px;
        height: 510px; 
        width: 500px;
     }

     .project-tags-container {
        flex-wrap: wrap;
  
    }

    .project-type-tag,
    .project-type-tag:last-of-type {
        margin: 16px 16px 8px 0;
    }


  }
  
