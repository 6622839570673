.button {
    all: unset;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.button:disabled {
    font-size: 20px;
    font-weight: 700;
    padding: 8px 24px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor:not-allowed;
    color: #fff;
    background-color: rgb(185, 185, 185);
}

.project-link {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    padding: 8px 24px;
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-top: 24px;
}

.project-link:hover,
.project-link:focus {
    transform: translateY(-4px);
}

.project-link#contact {
    width: fit-content;
    background-color: #5461C8;
    color: #fff;
}

.project-link svg {
    padding: 2px 0 0 4px;
    font-size: 24px;
}


@media (max-width: 768px) {
    .button:disabled {
     opacity: 1;
    }
}